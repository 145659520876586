<template>
  <div style="width:100%">
  <div class="head-region">
      <div class="back-btn">
        <el-button type="primary" size="mini" icon="el-icon-back" @click="editDetail()">返回上级</el-button>
      </div>
      <div class="head-path">
        <span >财务对账单</span>/<span >{{param.schoolName}}</span>
        /<span >{{param.timeSolt}}</span>商品销售统计
      </div>
    </div>
    <el-table :data="list" 
    :tree-props="{children: 'childrenList', hasChildren: 'hasChildren'}" 
    row-key="id" stripe  style="max-width: 100%;margin-top:15px;box-shadow: 0 1px 4px 0 rgba(0,0,0,0.1);">
      <el-table-column type="index" align="center" label="序号" width="50"></el-table-column>
      <el-table-column label="商品名称" align="center" show-overflow-tooltip prop="goodsName"></el-table-column>
      <el-table-column label="单价（元）" align="center" show-overflow-tooltip prop="unitPrice"></el-table-column>
      <el-table-column label="单位" align="center" show-overflow-tooltip prop="saleMeasureUnitName"></el-table-column>
      <el-table-column label="单计量销售数量" align="center" show-overflow-tooltip prop="singleMeasurementNum"></el-table-column>
      <el-table-column label="多计量销售数量" align="center" show-overflow-tooltip prop="multiMeasurementNum"></el-table-column>


    </el-table>
    <el-pagination layout="total, prev, pager, next,sizes" @size-change="handleSizeChange" @current-change="handleCurrentChange" background :page-size="form.pageSize" :current-page="form.pageNum" :total="total"></el-pagination>
 
      
  </div>
</template>

<script>
import { getChildPageListOfTime } from '@/api/index.js'
export default {
  name: 'financeTime',
  props: {
    param:{
      type: Object,
      default: () =>{}
    }
  }, 
  components: {},
  data() {
    return {
      form: {
        pageNum: 1,          //页码
        pageSize: 10,          //每页条数
        timeSolt:'',
        schoolName:'',     
      },
      list: [],
      total: 0,
    }
    
  },
  created() {
    this.form.timeSolt = this.param.timeSolt
    this.form.schoolName = this.param.schoolName
    this.getList();
  },
  methods: {
    editDetail() {
      this.$emit('changeShowType', 'list')
    },

    getList() {
      getChildPageListOfTime(this.form).then(res => {
        if (res.code != 200) return
        this.list = res.data.records
        this.total = res.data.total
      })
    },
    handleSizeChange(val) {
      this.form.pageSize = val
      this.handleCurrentChange(1)
    },
    handleCurrentChange(val) {
      this.form.pageNum = val
      this.getList()
    },

  }
}
</script>

<style lang="scss" scoped>
.head-region{
  display: flex;
  .back-btn{
    line-height: 30px;
    flex: 0 0 130px;
  }
  .head-path{
    flex:1;
    text-align: left;
    line-height: 32px;
    font-size:14px;
    span{
      margin-left:5px;
      margin-right:5px;
      
      color:#606266;
    }
  }
}
</style>